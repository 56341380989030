import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Table,Tag,Button, Modal} from "antd";
import './App.css';
import moment from 'moment';
import {ApiHeader,strings,apiurl,RESOURCE_TYPES} from './data';
import EditCarpark from './EditCarpark';
const locale = 'EN';

class PendingForAction extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            systemData:null,
            loading:false,             
            triggerReload:false,
            showalertmodel:false,
            selectedBooking:null,
            isediting:false,
            iseditop:false,
            alertmessage:"",
            parkingdata:[],
        }

        this.columns = [            
            {
                title: strings.time,
                dataIndex: 'date',
                key: 'date',
                width:'20%',
                render: (a, b) => (a.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'),'pm'))
            },/* {
                title: strings.location,
                dataIndex: 'location1',
                key: 'location1',
                width:'10%',
                sorter: (a, b) => a.location1.localeCompare(b.location1, 'en', { numeric: true }),
            }, */{
                title: strings.stafftype,
                dataIndex: 'staffType',
                key: 'staffType',
                width:'15%',
                sorter: (a, b) => a.staffType.localeCompare(b.staffType, 'en', { numeric: true }),
                render: (a, b) => (a !== null ? this.getStaffType(a):'')
            },{
                title: strings.carnumber,
                dataIndex: 'carNumber',
                key: 'carNumber',
                width:'10%',
                sorter: (a, b) => a.carNumber.localeCompare(b.carNumber, 'en', { numeric: true }),
            },{
                title:strings.requestedby,
                dataIndex: 'requestedBy',
                key: 'requestedBy',
                width:'15%',
                
                sorter: (a, b) => a.requestedBy.localeCompare(b.requestedBy, 'en', { numeric: true }),
            },{
                title: strings.reason,
                dataIndex: 'reason',
                key: 'reason',
                width:'20%',
                sorter: (a, b) => a.reason.localeCompare(b.reason, 'en', { numeric: true }),
            },{
                title: strings.action,
                key: 'tags',
                dataIndex: 'tags',
                render: (tags, record) => (
                    <span>
                    {tags.map(tag => {
                        return <Tag color={'#455773'} key={tag} style={{marginTop:5}} onClick={()=>this.ontagclick({tag},record)}>{tag.toUpperCase()}</Tag>;
                    })}
                    </span>
                ),
            }
        ];
    
        
    }

    

    componentDidMount() {
        sessionStorage.setItem('tabNumber',18);
        ApiHeader.userId = sessionStorage.getItem("userId");
        var d = sessionStorage.getItem('systemdata');
        var sd = JSON.parse(d);
        this.setState({systemData:sd});
        this.searchPendingCarpark1();
    }
  
    componentWillUnmount() {
  
    }
    
    getStaffType=(a)=>{
        var stafftypes = [];
        if(strings.getLanguage() === 'en'){
            stafftypes = this.state.systemData.staff_type_en.split(',');
        }else{
            stafftypes = this.state.systemData.staff_type_zh.split(',');
        }
        for(var i=0; i<stafftypes.length; i++){
            if(a === i+1){
                return stafftypes[i];
            }
        }
    }

    searchPendingCarpark1=()=>{
        this.setState({loading:true,parkingdata:[]})
        var rdata = {};
        rdata.resourceType = RESOURCE_TYPES.OPERATIONAL_CARPARK;        
        rdata.userId = ApiHeader.userId;
        rdata.state = 'pending_admin';
        
        fetch(apiurl+'booking/action', {
            headers: ApiHeader,
            method: 'POST', 
            credentials: 'same-origin',  
            body: JSON.stringify(rdata),         
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){
                    var bs = data.data.bookings;
                    
                    var bs1 = [];
                    var resource = this.state.systemData.resource;
                    for(var i=0;i<bs.length;i++){
                        var b1 = {};
                        b1.key = bs[i].id;
                        b1.key1 = i;
                        b1.id = bs[i].id;
                        b1.startTime = bs[i].startTime;
                        b1.endTime = bs[i].endTime;
                        var ds = moment.unix((bs[i].startTime/1000)).format("YYYY-MM-DD hh:mmA") +"-"+moment.unix((bs[i].endTime/1000)).format("hh:mmA");
                        b1.carNumber = bs[i].carNumber;
                        b1.date = ds;
                        b1.tags = [strings.approve, strings.reject, strings.view];
                        b1.requestedBy = bs[i].userName;
                        b1.reason = bs[i].reason;
                        b1.remark = bs[i].remark;
                        b1.staffType = bs[i].staffType;
                        b1.state = bs[i].state;
                        b1.isReminder = bs[i].isReminder;
                        b1.groupName = bs[i].groupName;
                        b1.firstcontact = bs[i].contactPerson1 +'('+bs[i].contactPhone1+')';
                        b1.secondcontact = bs[i].contactPerson2 +'('+bs[i].contactPhone2+')';     
                        b1.salutation1 = bs[i].salutation1;  
                        b1.salutation2 = bs[i].salutation2;                   
                        b1.type = strings.operational;
                        b1.approverName = bs[i].approverName;
                        b1.resourceType = RESOURCE_TYPES.OPERATIONAL_CARPARK;
                        b1.approverId = bs[i].approverId;
                        b1.division = bs[i].division;
                        b1.isMechanized = bs[i].isMechanized;
                        for(var j=0;j<resource.length;j++){
                            if(resource[j].id === bs[i].resourceId){
                                if(locale ==='EN'){
                                    b1.floor = resource[j].locationEN.split(',')[1];
                                    b1.location = resource[j].locationEN.split(',')[0];
                                    b1.location1 = resource[j].locationEN.split(',')[0]+" "+resource[j].locationEN.split(',')[1];
                                }else{
                                    b1.floor = resource[j].locationZH.split(',')[1];
                                    b1.location = resource[j].locationZH.split(',')[0];
                                    b1.location1 = resource[j].locationZH.split(',')[0]+" "+resource[j].locationZH.split(',')[1];
                                }
                                b1.resource = resource[j];
                                break;
                            }
                        }
                        bs1.push(b1);

                    }
                    this.setState({parkingdata:bs1,loading:false,count:bs1.length});
                    
                
                }else{
                    this.setState({alertmessage:strings.errorcode+" "+data.error_code,showalertmodel:true})
                }
                this.setState({loading:false})
        })
        .catch(e => console.warn(e))
    }


    ontagclick=(tag,b)=>{
        if(tag.tag == strings.approve){            
            this.approveBooking(b.id);
        }else if(tag.tag == strings.reject){
           this.rejectBooking(b.id);
        }else if(tag.tag == strings.view){
            this.setState({isediting:true,selectedBooking:b,iseditop:false});
        }           
    }

    approveBooking=(id)=>{
        
        var rdata = {};
        rdata.bookingId=id;
        fetch(apiurl+'booking/approve', {
        headers: ApiHeader,
        method: 'POST',   
        credentials: 'same-origin',
        body: JSON.stringify(rdata), 
        }).then(response => {
            if (response.ok) {
            return response.json();
            } else {
            throw new Error('Something went wrong ...');
            }
        })
        .then(data =>{
            if(data.code == "1"){
                this.setState({alertmessage:strings.approvedsuccess,showalertmodel:true})
                this.searchPendingCarpark1();
            }else{
                this.setState({alertmessage:strings.errorcode+" "+data.error_code,showalertmodel:true})
            }
        })
        .catch(e => console.warn(e))
        
    }
    
    rejectBooking=(id)=>{
        
        var rdata = {};
        rdata.bookingId=id;
        fetch(apiurl+'booking/reject', {
        headers: ApiHeader,
        method: 'POST',   
        credentials: 'same-origin',
        body: JSON.stringify(rdata), 
        }).then(response => {
            if (response.ok) {
            return response.json();
            } else {
            throw new Error('Something went wrong ...');
            }
        })
        .then(data =>{
            if(data.code == "1"){
                this.setState({alertmessage:strings.rejectedsuccess,showalertmodel:true})
                this.searchPendingCarpark1();
            }else{
                this.setState({alertmessage:strings.errorcode+" "+data.error_code,showalertmodel:true})
            }
        })
        .catch(e => console.warn(e))
        
    }
    findErrorMessage=(msg)=>{
        return strings.errorcodes[msg.split(' : ')[1]] != null ?strings.errorcodes[msg.split(' : ')[1]]:msg;
    }

    editback=()=>{                  
        this.setState({isediting:false,iseditop:false})
    }

    render() {
    
    
        return (
            this.state.isediting?                   
                    <EditCarpark sb={this.state.selectedBooking} isEdit={this.state.iseditop} backbtn={this.editback}/>         
                    : 
            <div className='Tab2Div'>
                <div style={{marginTop:40,marginLeft:10}}><span style={{fontSize:15,fontWeight:500,color:'#455773'}}>{strings.pendingtransfercarparkapproval}</span></div>
                <div style={{marginTop:10}}>
                    <div className="table-operations">
                    </div>
                    <Table style={{marginTop:12,backgroundColor:'#fff'}} columns={this.columns} loading={this.state.loading} pagination={{ pageSize: 5 }} dataSource={this.state.parkingdata} />
                    <div className="CountOfData">
                        <span className="CountText">{strings.totalcount} {this.state.parkingdata.length}</span>
                    </div>        
                </div>
                <Modal
                    title={strings.alert}
                    visible={this.state.showalertmodel}
                    onCancel={()=>this.setState({showalertmodel:false})}
                    footer={[                  
                        <Button key="submit" className='subscribe1' type="danger" onClick={()=>{this.setState({showalertmodel:false});if(this.state.triggerReload)window.location.reload();}}>
                        {strings.ok}
                        </Button>,
                    ]}
                    >
                        <span className="TitleOfTheBox">{strings.errormessage}</span><br/>
                        <span className="TitleOfTheBox1">{this.state.showalertmodel?this.findErrorMessage(this.state.alertmessage):''}</span>
                </Modal>
            </div>
        );
    }
}    
export default PendingForAction;

