import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { DatePicker, Input, Row, Col, Calendar, Button, Select, Modal, Checkbox, message, Switch } from 'antd';
import TableDragSelect from "react-table-drag-select";
import moment from 'moment';
import "react-table-drag-select/style.css";
import './App.css';
import './drag.css';
import { ApiHeader, strings, apiurl, RESOURCE_TYPES } from './data';
const Option = Select.Option;

const timelines = ['8AM', '8:15AM', '8:30AM', '8:45AM',
  '9AM', '9:15AM', '9:30AM', '9:45AM', '10AM', '10:15AM', '10:30AM', '10:45AM', '11AM', '11:15AM', '11:30AM', '11:45AM',
  '12PM', '12:15PM', '12:30PM', '12:45PM', '1PM', '1:15PM', '1:30PM', '1:45PM', '2PM', '2:15PM', '2:30PM', '2:45PM',
  '3PM', '3:15PM', '3:30PM', '3:45PM', '4PM', '4:15PM', '4:30PM', '4:45PM', '5PM', '5:15PM', '5:30PM', '5:45PM',
  '6PM', '6:15PM', '6:30PM', '6:45PM', '7PM', '7:15PM', '7:30PM', '7:45PM', '8PM', '8:15PM', '8:30PM', '8:45PM',
  '9PM'];

  const timelines1 = ['8AM', '8:15AM', '8:30AM', '8:45AM',
  '9AM', '9:15AM', '9:30AM', '9:45AM', '10AM', '10:15AM', '10:30AM', '10:45AM', '11AM', '11:15AM', '11:30AM', '11:45AM',
  '12PM', '12:15PM', '12:30PM', '12:45PM', '1PM', '1:15PM', '1:30PM', '1:45PM', '2PM', '2:15PM', '2:30PM', '2:45PM',
  '3PM', '3:15PM', '3:30PM', '3:45PM', '4PM', '4:15PM', '4:30PM', '4:45PM', '5PM', '5:15PM', '5:30PM', '5:45PM',
  '6PM', '6:15PM', '6:30PM', '6:45PM', '7PM', '7:15PM', '7:30PM', '7:45PM', '8PM', '8:15PM', '8:30PM', '8:45PM',
  '9PM'];

const timelines2 = ['08:00','08:15','08:30', '08:45',
  '09:00', '09:15', '09:30', '09:45', '10:00', '10:15', '10:30', '10:45', '11:00', '11:15', '11:30', '11:45',
  '12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30', '14:45',
  '15:00', '15:15', '15:30', '15:45', '16:00', '16:15', '16:30', '16:45', '17:00', '17:15', '17:30', '17:45',
  '18:00', '18:15', '18:30', '18:45', '19:00', '19:15', '19:30', '19:45', '20:00', '20:15', '20:30', '20:45',
  '21:00'];
const locale = 'EN';

class EditCarpark extends Component {
  constructor(props) {
    super(props);
    ApiHeader.userId = sessionStorage.getItem("userId");
    this.state = {
      userId: ApiHeader.userId,
      resourceId: 1,
      startTime: 0,
      endTime: 0,
      cdatevalue: '',
      isReminder:false,
      dateValue: this.getCurrentDate(),
      timeValue: null,
      contactPhone1: "",
      contactPerson1: "",
      salutation1:"",
      salutation2:"",
      contactPhone2: "",
      contactPerson2: "",
      reason: "",
      carnumber: "",
      tbc: false,
      remark: "",
      staffType:'',
      approverId: 0,
      approverName: '',
      resourceType: RESOURCE_TYPES.VISITOR_CARPARK,
      resources: [],
      equipment: [],
      loading: false,
      selectedTime: '',
      systemData: null,
      loading3: false,
      cp1err: false,
      cph1err: false,
      reasonerr: false,
      carnumerr: false,
      stafftypeerr: false,
      timeerr: false,
      bid: 0,
      triggerBack: false,
      showalertmodel: false,
      alertmessage: "",
      floor: '',
      location: '',
      iselectric: false,
      visitorcompany: '',
      visitorname: '',
      purpose: '',
      division: '',
      isMechanized: 'Y',
      visitorNameerr: false,
      visitorcompanyerr: false,
      cells: [
        [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
          false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
          false, false, false, false, false, false, false, false, false, false, false, false, false],
      ],
      cellstemp: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false, false, false, false, false, false],
      cells1: [
        [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
          false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
          false, false, false, false, false, false, false, false, false, false, false, false, false],
      ],
      cellstemp1: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false, false, false, false, false, false],      
      isAgree:true,
      agreeerr:false,
      isofficehourmsg:false,
      trs:[],
    }
  }

  componentDidMount() {
    var d = sessionStorage.getItem('systemdata');
    var sd = JSON.parse(d);
    this.setState({ resources: sd.resource, equipment: sd.equipment, systemData: sd });
    var b = this.props.sb;
    this.setState({
      floor: b.floor,
      location: b.location,
      contactPhone1: b.firstcontact.split('(')[1] != null ? b.firstcontact.split('(')[1].slice(0, -1) : '',
      contactPerson1: b.firstcontact.split('(')[0] != null ? b.firstcontact.split('(')[0] : '',
      salutation1: b.salutation1 != null ? b.salutation1:'',
      salutation2: b.salutation2 != null ? b.salutation2:'',
      contactPhone2: b.secondcontact.split('(')[1] != null ? b.secondcontact.split('(')[1].slice(0, -1) : '',
      contactPerson2: b.secondcontact.split('(')[0] != null ? b.secondcontact.split('(')[0] : '',
      reason: b.reason != null ? b.reason : '',
      remark: b.remark != null ? b.remark : '',
      staffType: b.staffType != null ? b.staffType : '',
      carnumber: b.carNumber != null ? b.carNumber : '',
      approverName: b.approverName != null ? b.approverName : '',
      iselectric: b.isElectric === 'yes' ? true : false,
      visitorcompany: b.visitorCompany,
      visitorname: b.visitorName,
      purpose: b.purpose,
      division: b.division,
      isReminder:b.isReminder==='Y'?true:false,
      isMechanized: b.isMechanized,
      bid: b.id,
      startTime:b.startTime,
      endTime:b.endTime,
      selectedTime: b.date.split(" ")[1],
      resourceType: b.resourceType != null ? b.resourceType : '',
      cdatevalue: moment(b.date.split(" ")[0].split("-")[0] + "-" + b.date.split(" ")[0].split("-")[1] + "-" + b.date.split(" ")[0].split("-")[2]),
      dateValue: b.date.split(" ")[0].split("-")[0] + "-" + b.date.split(" ")[0].split("-")[1] + "-" + b.date.split(" ")[0].split("-")[2]
    })
    if (b.carNumber == 'tobeconfirmed') {
      this.setState({ carnumber: '', tbc: true });
    }
    var isstart = false;
    var times = b.date.split(" ")[1].split('-');
    var t1 = times[0];
    if (t1.startsWith('0')) {
      t1 = t1.slice(1);
    }
    t1 = t1.replace(':00', '');
    var t2 = times[1];
    if (t2.startsWith('0')) {
      t2 = t2.slice(1);
    }
    t2 = t2.replace(':00', '');
    if(b.resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK){
      for (var i = 0; i < timelines1.length; i++) {
        if (timelines1[i] === t1 || isstart == true) {
          if (timelines1[i] === t2) {
            break;
          }
          var cells = this.state.cells1;
          cells[0][i] = true;
          this.setState({ cells1: cells, cellstemp1:cells[0]  });
          isstart = true;
        }
      }
    }else{
      for (var i = 0; i < timelines.length; i++) {
        if (timelines[i] === t1 || isstart == true) {
          if (timelines[i] === t2) {
            break;
          }
          var cells = this.state.cells;
          cells[0][i] = true;
          this.setState({ cells: cells, cellstemp:cells[0]  });
          isstart = true;
        }
      }
    }
    

    var st = b.startTime;
    var et = b.endTime;
    var datestr = (moment(b.startTime).format('YYYY-MM-DD')) + ' 00:00:00';
    var ts = moment(datestr, "YYYY-MM-DD HH:mm:SS").valueOf();
    this.setState({ isofficehourmsg: et>parseInt(ts+64800000) || st<parseInt(ts+30600000)?true:false });
    this.getBookingForDay(st);

  }

  componentWillUnmount() {

  }

  getBookingForDay = (d) => {
    var rdata = {};
    rdata.date =d;
    fetch(apiurl + 'resource/currentvisitorparking', {
        headers: ApiHeader,
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify(rdata),
    }).then(response => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error('Something went wrong ...');
        }
    })
    .then(data => {
        if (data.code == "1") {
            var vbs = data.data.vbs;
            var tbs = data.data.tbs;
            var specificcurrent = data.data.active1;
            var defaultcurrent = data.data.active2;
            var vtcount = 0, otcount = 0; 
            if(specificcurrent){
              vtcount = specificcurrent.visitorQuota;
              otcount = specificcurrent.operationalQuota;
            }else{
              vtcount = defaultcurrent.visitorQuota;
              otcount = defaultcurrent.operationalQuota;
            }
            var trs = [];
            var tds = [], tds1 = [];
            var datestr = moment(d).format('YYYY-MM-DD') + ' 00:00:00';
            var ts = moment(datestr, "YYYY-MM-DD HH:mm:SS").valueOf();
            ts = (ts / 1000) + 28800;
            for (var i = 0; i < timelines.length; i++) {
                if (i + 1 === timelines.length) {
                    break;
                }

                var vcount = 0, ocount = 0;
                var st = ts + (i * 900);
                var et = ts + ((i + 1) * 900);

                for (var b = 0; b < vbs.length; b++) {
                    var sd = vbs[b].startTime / 1000;
                    var ed = vbs[b].groupEndTime ? (vbs[b].groupEndTime/1000) : (vbs[b].endTime/1000);
                    if (sd < et && ed > st) {
                        vcount = vcount + 1;
                    }
                }
                var datestr = this.state.dateValue+' '+timelines2[i]
                var cdate = moment(datestr,'YYYY-MM-DD HH:mm');
                if(cdate.valueOf()<=moment().valueOf()){
                  tds.push({ id: i+"-1", isBooked: true });
                }else{
                  tds.push({ id: i+"-1", isBooked: vcount>=vtcount?true:false });
                }
                

                for (var b = 0; b < tbs.length; b++) {
                    var sd = tbs[b].startTime / 1000;
                    var ed = tbs[b].groupEndTime ? (tbs[b].groupEndTime/1000) : (tbs[b].endTime/1000);
                    if (sd < et && ed > st) {
                        ocount = ocount + 1;
                    }
                }
                if(cdate.valueOf()<=moment().valueOf()){
                  tds1.push({ id: i+"-2", isBooked: true });
                }else{
                  tds1.push({ id: i+"-2", isBooked: ocount>=otcount?true:false });
                }
                
            }
            trs.push({ id: 1, tds: tds });
            trs.push({ id: 2, tds: tds1 });
            this.setState({ trs: trs})
        } else {
            this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
        }
        this.setState({ loading: false })
    })
    .catch(e => {
        console.warn(e);
        this.setState({ loading: false });
    })
  } 


  getCurrentDate = () => {
    var d = new Date();
    return [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');
  }


  modifyBooking = () => {
    this.setState({ loading: true, timeerr:false });
    var rdata = {};
    var isvalid = true;
    if (this.state.startTime == 0 || this.state.endTime == 0) {
      this.setState({ timeerr: true });
      isvalid = false;
    } else {
      if (this.state.startTime <= new Date().getTime()) {
        this.setState({ alertmessage: strings.timeslorerr1, showalertmodel: true, loading: false })
        return;
      }
      if (this.state.resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK) {
        var newdate = new Date();
        newdate.setHours(23);
        newdate.setMinutes(59);
        newdate.setSeconds(59);
        newdate.setMilliseconds(999);
        if (this.state.startTime > newdate.getTime() + (1000 * 60 * 60 * 24 * 14)) {
          this.setState({ alertmessage: strings.timeslorerr3, showalertmodel: true, loading: false })
          return;
        }/* else if(moment(this.state.startTime).weekday() === 6 || moment(this.state.startTime).weekday() === 0){
          this.setState({ alertmessage: strings.timeslorerr5, showalertmodel: true, loading: false })
          return;
        } */
      } else {
        var newdate = new Date();
        newdate.setHours(23);
        newdate.setMinutes(59);
        newdate.setSeconds(59);
        newdate.setMilliseconds(999);
        newdate.setMonth(newdate.getMonth() + 3);
        if (this.state.startTime > newdate.getTime()) {
          this.setState({ alertmessage: strings.timeslorerr4, showalertmodel: true, loading: false })
          return;
        }
      }

      rdata.startTime = this.state.startTime;
      rdata.endTime = this.state.endTime;
    }
    if (this.state.contactPhone1 == "") {
      this.setState({ cph1err: true });
      isvalid = false;
    } else {

      if (this.state.contactPhone1.length <= 45) {
        rdata.contactPhone = this.state.contactPhone1;
      } else {
        message.warning(strings.contactphonemaxlength)
        isvalid = false;
      }
    }

    if (this.state.contactPerson1 == "") {
      this.setState({ cp1err: true });
      isvalid = false;
    } else {

      if (this.state.contactPerson1.length <= 100) {
        rdata.contactPerson = this.state.contactPerson1;
      } else {
        message.warning(strings.contactpersonmaxlength)
        isvalid = false;
      }
    }

    if (this.state.contactPhone2.length <= 45) {
      rdata.contactPhone1 = this.state.contactPhone2;
    } else {
      message.warning(strings.contactphonemaxlength)
      isvalid = false;
    }

    if (this.state.contactPerson2.length <= 100) {
      rdata.contactPerson1 = this.state.contactPerson2;
    } else {
      message.warning(strings.contactpersonmaxlength)
      isvalid = false;
    }

    if (this.state.remark.length <= 500) {
      rdata.remark = this.state.remark;
    } else {
      message.warning(strings.remarkmaxlength)
      isvalid = false;
    }


    if (this.state.reason == "") {
      this.setState({ reasonerr: true });
      isvalid = false;
    } else {

      if (this.state.reason.length <= 100) {
        rdata.reason = this.state.reason;
      } else {
        message.warning(strings.reasonmaxlength)
        isvalid = false;
      }
    }

    if (this.state.resourceType !== RESOURCE_TYPES.VISITOR_CARPARK) {
      if (this.state.carnumber == "") {
        this.setState({ carnumerr: true });
        isvalid = false;
      } else {
        if (this.state.carnumber.length <= 100) {
          if (/[^a-zA-Z0-9]/.test(this.state.carnumber) === false && this.state.carnumber[0] !== '0' && !this.state.carnumber.includes('I') && !this.state.carnumber.includes('O') && !this.state.carnumber.includes('Q')) {
            rdata.carNumber = this.state.carnumber;
          } else {
            message.warning(strings.carnumbernotvalid)
            isvalid = false;
          }
        } else {
          message.warning(strings.carnumbermaxlength)
          isvalid = false;
        }
      }
      if(this.state.staffType !== null && this.state.staffType !== ''){
        rdata.staffType = this.state.staffType;
      }else{
          this.setState({ stafftypeerr: true });
          isvalid = false;
      }
    } else {
      if (this.state.tbc == true) {
        rdata.carNumber = 'tobeconfirmed';
      } else {
        if (this.state.carnumber.length <= 100) {
          if (/[^a-zA-Z0-9]/.test(this.state.carnumber) === false && this.state.carnumber[0] !== '0' && !this.state.carnumber.includes('I') && !this.state.carnumber.includes('O') && !this.state.carnumber.includes('Q')) {
            rdata.carNumber = this.state.carnumber;
          } else {
            message.warning(strings.carnumbernotvalid)
            isvalid = false;
          }
        } else {
          message.warning(strings.carnumbermaxlength)
          isvalid = false;
        }
      }

      rdata.isElectric = this.state.iselectric ? 'yes' : 'no';
      if (this.state.visitorname == "") {
        this.setState({ visitorNameerr: true });
        isvalid = false;
      } else {
        rdata.visitorName = this.state.visitorname;
      }

      if (this.state.visitorcompany == "") {
        this.setState({ visitorcompanyerr: true });
        isvalid = false;
      } else {
        rdata.visitorCompany = this.state.visitorcompany;
      }
      rdata.purpose = this.state.purpose;
      
    }
    
    rdata.division = this.state.division;
    rdata.isMechanized = this.state.isMechanized;
    rdata.salutation = this.state.salutation1;
    rdata.salutation1 = this.state.salutation2;
    /* if(this.state.isMechanized === 'Y'){
      if(!this.state.isAgree){
        this.setState({ agreeerr: true });
        isvalid = false;
      }else{
        this.setState({ agreeerr: false });
      }
    } */
    rdata.id = this.state.bid;
    rdata.isReminder = this.state.isReminder?'Y':'N';
    if (isvalid == false) {
      this.setState({ loading: false })
      return;
    }
    fetch(apiurl + 'booking/edit', {
      headers: ApiHeader,
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(rdata),
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    })
      .then(data => {
        this.setState({ loading: false })
        if (data.code == "1") {
          this.setState({ alertmessage: strings.updatesuccess, showalertmodel: true, triggerBack:true })
        } else {
          this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
        }
      })
      .catch(e => console.warn(e))
  }


  clearScreen = () => {

    var b = this.props.sb;
    this.setState({
      contactPhone1: b.firstcontact.split('(')[1] != null ? b.firstcontact.split('(')[1].slice(0, -1) : '',
      contactPerson1: b.firstcontact.split('(')[0] != null ? b.firstcontact.split('(')[0] : '',
      salutation1: b.salutation1 != null ? b.salutation1:'',
      salutation2: b.salutation2 != null ? b.salutation2:'',
      contactPhone2: b.secondcontact.split('(')[1] != null ? b.secondcontact.split('(')[1].slice(0, -1) : '',
      contactPerson2: b.secondcontact.split('(')[0] != null ? b.secondcontact.split('(')[0] : '',
      reason: b.reason != null ? b.reason : '',
      remark: b.remark != null ? b.remark : '',
      staffType: b.staffType != null ? b.staffType : '',
      iselectric: b.isElectric === 'yes' ? true : false,
      visitorcompany: b.visitorCompany,
      visitorname: b.visitorName,
      isReminder:b.isReminder==='Y'?true:false,
      purpose: b.purpose,
      division: b.division,
      startTime:b.startTime,
      endTime:b.endTime,
      timeerr: false,
      isMechanized: b.isMechanized,
      carnumber: b.carNumber != null ? b.carNumber : '',
      approverName: b.approverName != null ? b.approverName : '',
      isAgree:true,
      agreeerr:false,
      cdatevalue: moment(b.date.split(" ")[0].split("-")[0] + "-" + b.date.split(" ")[0].split("-")[1] + "-" + b.date.split(" ")[0].split("-")[2]),
      dateValue: b.date.split(" ")[0].split("-")[0] + "-" + b.date.split(" ")[0].split("-")[1] + "-" + b.date.split(" ")[0].split("-")[2]
    })
    if (b.carNumber == 'tobeconfirmed') {
      this.setState({ carnumber: '', tbc: true });
    }
    var isstart = false;
    var times = b.date.split(" ")[1].split('-');
    var t1 = times[0];
    if (t1.startsWith('0')) {
      t1 = t1.slice(1);
    }
    t1 = t1.replace(':00', '');
    var t2 = times[1];
    if (t2.startsWith('0')) {
      t2 = t2.slice(1);
    }
    t2 = t2.replace(':00', '');
    if(this.state.resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK){
      var cells = this.state.cells1;
      for (var i = 0; i < cells[0].length; i++) {
        cells[0][i] = false;
      }
      for (var i = 0; i < timelines1.length; i++) {
        if (timelines1[i] === t1 || isstart == true) {
          if (timelines1[i] === t2) {
            break;
          }
          cells[0][i] = true;        
          isstart = true;
        }
      }
      this.setState({ cells1: cells, cellstemp1: cells[0] });
    }else{
      var cells = this.state.cells;
      for (var i = 0; i < cells[0].length; i++) {
        cells[0][i] = false;
      }
      for (var i = 0; i < timelines.length; i++) {
        if (timelines[i] === t1 || isstart == true) {
          if (timelines[i] === t2) {
            break;
          }
          cells[0][i] = true;        
          isstart = true;
        }
      }
      this.setState({ cells: cells, cellstemp: cells[0] });
    }
    
  }

  onIsReminderChange=(e)=>{
    this.setState({isReminder:e.target.checked});
  }

  ontbcchange = (e) => {
    this.setState({ tbc: e.target.checked });
    if (e.target.checked == true) {
      this.setState({ carnumber: "" });
    }
  }

  findErrorMessage = (msg) => {
    return strings.errorcodes[msg.split(' : ')[1]] != null ? strings.errorcodes[msg.split(' : ')[1]] : msg;
  }

  rendertd = () => {
    var res = [];

    for (var i = 0; i < 52; i++) {
      res.push(<td key={i} className='dragselecttimeline' ></td>);
    }
    return res;
  }

  rendertd1 = () => {
    var res = [];
    for (var i = 0; i < 52; i++) {
      res.push(<td key={i} className='dragselecttimeline' ></td>);
    }
    return res;
  }

  handleTimelineChange = (cells) => {
    var times = [];
    var times1 = [];
    var index = 0;
    if(this.state.resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK){
      var celltemp = this.state.cellstemp1;
      for (var i = 0; i < celltemp.length; i++) {
        if (celltemp[i] == true && cells[0][i] == true) {
          cells[0][i] = false;
        }
      }
      for (var i = 0; i < cells[0].length; i++) {
        if (cells[0][i] == true) {
          if (times.length == 0) {
            index = i;
            times.push(timelines1[i]);
            times1.push((i * 900000) + 28800000);
          } else {
            times1.push(((i + 1) * 900000) + 28800000);
            times.push(timelines1[i + 1]);
          }
        }
      }
      if (times.length == 1) {
        times.push(timelines1[index + 1]);
        times1.push(((index + 1) * 900000) + 28800000);
      }
      this.setState({ cells1: cells, cellstemp1: cells[0] });
    }else{
      var celltemp = this.state.cellstemp;
      for (var i = 0; i < celltemp.length; i++) {
        if (celltemp[i] == true && cells[0][i] == true) {
          cells[0][i] = false;
        }
      }
      for (var i = 0; i < cells[0].length; i++) {
        if (cells[0][i] == true) {
          if (times.length == 0) {
            index = i;
            times.push(timelines[i]);
            times1.push((i * 900000) + 28800000);
          } else {
            times1.push(((i + 1) * 900000) + 28800000);
            times.push(timelines[i + 1]);
          }
        }
      }
      if (times.length == 1) {
        times.push(timelines[index + 1]);
        times1.push(((index + 1) * 900000) + 28800000);
      }
      this.setState({ cells: cells, cellstemp: cells[0] });
    }
    
    var datestr = this.state.dateValue + ' 00:00:00';
    var ts = moment(datestr, "YYYY-MM-DD HH:mm:SS").valueOf();
    if (times[0] != undefined && times[times.length - 1] != undefined){
      var et = parseInt(ts + times1[times1.length - 1]);
      var st = parseInt(ts + times1[0]);
      
      this.setState({ 
        selectedTime: times[0] + " - " + times[times.length - 1],
        startTime: st, endTime: et, 
        isofficehourmsg: et>parseInt(ts+64800000) || st<parseInt(ts+30600000)?true:false
      });
    }      
    else{
      this.setState({ selectedTime: '', startTime: 0, endTime: 0 });
    }
      
  }

  disabledDates=(d)=>{
    if(this.state.resourceType===RESOURCE_TYPES.VISITOR_CARPARK){
      return d.isAfter(moment().add(3,'months'));
    }else{
      return d.isAfter(moment().add(2,'weeks')) || moment(d).day() === 0 || moment(d).day() === 6;
    }    
  }

  onSelect = (value) => {
    this.setState({ cdatevalue: moment(value.format('YYYY-MM-DD')), dateValue:value.format('YYYY-MM-DD'), selectedTime: '' })
    if(this.state.resourceType === RESOURCE_TYPES.VISITOR_CARPARK){
      this.handleTimelineChange(this.state.cells)
    }else{
      this.handleTimelineChange(this.state.cells1)
    }    
    this.getBookingForDay(value.valueOf());
  }

  rendertd2 = (tds) => {
    var rds = [];
    for (var i = 0; i < tds.length; i++) {
      var d =  tds[i];
      rds.push(<td key={i} className='dragselecttimeline' style={{height:10,border: '0.5px solid rgb(235, 235, 235)',borderRight:i+1===tds.length?'0.5px solid rgb(235, 235, 235)':'0px',borderBottom:'0px',textAlign: 'center',backgroundColor: d.isBooked?'#dedede':'#fff' }}></td>);
             
    }
    return rds;
  }

  rendertd3 = (tds) => {
    var res = [];    
    for (var i = 0; i < tds.length; i++) {
      var d = tds[i];
      res.push(<td key={i} className='dragselecttimeline' style={{height:10,border: '0.5px solid rgb(235, 235, 235)',borderRight:i+1===tds.length?'0.5px solid rgb(235, 235, 235)':'0px',borderBottom:'0px',textAlign: 'center',backgroundColor: d.isBooked?'#dedede':'#fff' }}></td>);
    }    
    return res;
  }

  render() {

    return (

      <div>
        <Row>
          <Col span={4} className='bookingcalanderdiv'>
            <div style={{ backgroundColor: '#fff', borderRadius: 4 }}>
              <Calendar fullscreen={false} value={this.state.cdatevalue} onSelect={this.onSelect} disabledDate={this.disabledDates}/>
            </div>
          </Col>
          <Col span={20} className='bookinginfodiv1'>
            <div className="SecondBox" style={{ marginTop: 0 }}>
              <span className="TitleOfTheBox">{strings.bookacarpark}</span>
              <div>
                <Row xs={24} style={{ marginTop: 7 }}>
                  <Col md={4} style={{ marginTop: 10, }}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className="DateText">{strings.date}</span>
                  </Col>
                  <Col md={6} style={{ marginTop: 10, }}>
                    <div>
                      <Input size="large" className='bookingDatebox' value={this.state.dateValue} disabled />
                    </div>
                  </Col>
                  <Col md={4} style={{ marginTop: 10, }}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className={this.state.timeerr ? "DateTextErr" : "DateText"}>{strings.time}</span>
                  </Col>
                  <Col md={6} style={{ marginTop: 10, }}>
                    <div>
                      <Input size="large" className='bookingDatebox' value={this.state.selectedTime.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'), 'pm')} disabled />

                    </div>
                  </Col>
                </Row>
                <div style={{ marginTop: 20, height: 100 }}>
                  <table><tbody>
                    <tr className='headertimeline'>

                      <td >8<span>am</span>-9<span>am</span></td>
                      <td >9<span>am</span>-10<span>am</span></td>
                      <td >10<span>am</span>-11<span>am</span></td>
                      <td >11<span>am</span>-12<span>pm</span></td>
                      <td >12<span>pm</span>-1<span>pm</span></td>
                      <td >1<span>pm</span>-2<span>pm</span></td>
                      <td >2<span>pm</span>-3<span>pm</span></td>
                      <td >3<span>pm</span>-4<span>pm</span></td>
                      <td >4<span>pm</span>-5<span>pm</span></td>
                      <td >5<span>pm</span>-6<span>pm</span></td>
                      <td >6<span>pm</span>-7<span>pm</span></td>
                      <td >7<span>pm</span>-8<span>pm</span></td>
                      <td >8<span>pm</span>-9<span>pm</span></td>
                    </tr></tbody>
                  </table>
                  {this.state.resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK &&
                    <tr>
                      {this.rendertd2(this.state.trs.length>0?this.state.trs[1].tds:[])}
                    </tr>}
                  {this.state.resourceType === RESOURCE_TYPES.VISITOR_CARPARK && <tr>
                      {this.rendertd3(this.state.trs.length>0?this.state.trs[0].tds:[])}
                    </tr>
                  }
                  {this.state.resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK &&
                    <TableDragSelect value={this.state.cells1} onChange={this.handleTimelineChange}>
                      <tr>
                        {this.rendertd1()}
                      </tr>
                    </TableDragSelect>}
                    {this.state.resourceType === RESOURCE_TYPES.VISITOR_CARPARK && <TableDragSelect value={this.state.cells} onChange={this.handleTimelineChange}>
                      <tr>
                        {this.rendertd()}
                      </tr>
                    </TableDragSelect>
                  }
                </div>
                {/* {this.state.resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK && this.state.isofficehourmsg && <div><span className="LongText2" style={{marginLeft:0, color:'red'}}>{strings.nonofficehourmsg}</span></div>} */}
                {this.state.resourceType === RESOURCE_TYPES.VISITOR_CARPARK && <div><span className="LongText2" style={{marginLeft:0, color:'red'}}>{strings.visitorstaffnotice}</span></div>}
                {this.state.resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK && <div><span className="LongText2" style={{marginLeft:0, color:'red'}}>{strings.operationalstaffnotice}</span></div>}
                
                <Row xs={24} style={{ marginTop: 20, }} >                  
                  <Col md={4}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className="DateText">{strings.type}</span>
                  </Col>
                  <Col md={6}>
                    <div>
                      <Select size="large" className='bookingDatebox' value={this.state.resourceType} disabled>
                        <Option value={RESOURCE_TYPES.VISITOR_CARPARK}>{strings.visitor}</Option>
                        <Option value={RESOURCE_TYPES.OPERATIONAL_CARPARK}>{strings.operational}</Option>
                      </Select>
                    </div>
                  </Col>
                  <Col md={4}>
                    <span className="DateText" style={{ marginLeft: 12 }}>{strings.parkingspace}</span>
                  </Col>
                  <Col md={6}>
                    <div>
                      <span className="DateText" style={{fontWeight:'normal'}}>{this.state.isMechanized === 'Y'?strings.mechanized : strings.nonmechanized}</span>
                    </div>
                    {/* {this.state.isMechanized==='Y' && <span className="LongText2" style={{marginLeft:0}}>{strings.click} {' '}<a href='/srs_asset/user_manual.pdf' target="_blank" style={{color:'#ff7f27',textDecoration:'underline'}}>{strings.here}</a> {' '}{strings.todownloaduserguide}</span>} */}
                  </Col>
                </Row>
                {this.state.resourceType === RESOURCE_TYPES.VISITOR_CARPARK && <Row xs={24} style={{ marginTop: 20, }} >
                  <Col md={4}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}> </span>
                    <span style={{ marginLeft: 12 }} className="DateText" >{strings.iselectric}</span>
                  </Col>
                  <Col md={6}>
                    <div>
                      <Switch disabled={!this.props.isEdit} checked={this.state.iselectric} onChange={(e) => this.setState({ iselectric: e })} />
                    </div>
                  </Col>
                  <Col md={4}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className={this.state.visitorNameerr ? "DateTextErr" : "DateText"} >{strings.visitorname}</span>
                  </Col>
                  <Col md={6}>
                    <div>
                      <Input disabled={!this.props.isEdit} size="large" className='bookingDatebox' value={this.state.visitorname} placeholder={strings.nameofvisitor} onChange={e => this.setState({ visitorname: e.target.value })} />
                    </div>
                  </Col>
                </Row>}
                {/* {this.state.resourceType === RESOURCE_TYPES.VISITOR_CARPARK && this.state.iselectric && <div><span className="LongText2" style={{marginLeft:0, color:'red'}}>{strings.ecvehicalmsg}</span></div>}
 */}
                {this.state.resourceType === RESOURCE_TYPES.VISITOR_CARPARK && <Row xs={24} style={{ marginTop: 20, }} >
                  <Col md={4}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className={this.state.visitorcompanyerr ? "DateTextErr" : "DateText"} >{strings.visitorcompany}</span>
                  </Col>
                  <Col md={6}>
                    <div>
                      <Input disabled={!this.props.isEdit} size="large" className='bookingDatebox' value={this.state.visitorcompany} placeholder={strings.nameofcompanydepartment} onChange={e => this.setState({ visitorcompany: e.target.value })} />
                    </div>
                  </Col>
                  <Col md={4}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className="DateText" >{strings.purpose}</span>
                  </Col>
                  <Col md={6}>
                    <div>
                      <Select disabled={!this.props.isEdit} size="large" className='bookingDatebox' value={this.state.purpose} onChange={e => this.setState({ purpose: e })}>
                        <Option value="meeting">{strings.meeting}</Option>
                        <Option value="training">{strings.training}</Option>
                        <Option value="briefing">{strings.briefing}</Option>
                        <Option value="recruitment_board">{strings.recruitmentboard}</Option>
                        <Option value="promotion_board">{strings.promotionboard}</Option>
                        <Option value="">{strings.others}</Option>
                      </Select>
                    </div>
                  </Col>
                </Row>}

                <Row xs={24} style={{ marginTop: 20, }} >
                  <Col md={4} >
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className={this.state.reasonerr ? "DateTextErr" : "DateText"}>{strings.reason}</span>
                  </Col>
                  <Col md={20}>
                    <div>
                      <Input disabled={!this.props.isEdit || this.state.resourceType !== RESOURCE_TYPES.VISITOR_CARPARK} size="large" style={{ width: '74%' }} value={this.state.reason} placeholder={strings.pleasefill+' '+strings.reason} onChange={e => this.setState({ reason: e.target.value })} />
                    </div>
                  </Col>
                </Row>

                <Row xs={24} style={{ marginTop: 20, }} >
                  <Col md={4} hidden={!this.state.isvisitor}>
                    <span style={{ marginLeft: 12 }} className="DateText">{strings.carplatenumber}</span>
                  </Col>
                  <Col md={4} hidden={this.state.isvisitor}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className={this.state.carnumerr ? "DateTextErr" : "DateText"}>{strings.carplatenumber}</span>
                  </Col>
                  <Col md={5}>
                    <div>
                      <Input disabled={!this.props.isEdit || this.state.resourceType !== RESOURCE_TYPES.VISITOR_CARPARK || this.state.tbc == true} size="large" className='bookingDatebox' value={this.state.carnumber} placeholder={strings.pleasefill+' '+strings.carplatenumber} onChange={e => this.setState({ carnumber: e.target.value?e.target.value.toUpperCase():'' })} />
                    </div>
                  </Col>
                  <Col md={5} hidden={this.state.resourceType !== RESOURCE_TYPES.VISITOR_CARPARK}>
                    <div>
                      <Checkbox onChange={(e) => this.ontbcchange(e)} style={{ marginLeft: 20, marginTop:10 }} disabled={!this.props.isEdit} checked={this.state.tbc}>{strings.tobeconfirmed}</Checkbox>
                    </div>
                  </Col>
                </Row>
                <Row xs={24} style={{ marginTop: 20, }}>
                  <Col md={4}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className="DateText">{strings.approver}</span>
                  </Col>
                  <Col md={4}>
                    <div>
                      <Input size="large" className='bookingDatebox' value={this.state.approverName} placeholder={strings.pleasefill+' '+strings.approver} disabled />
                    </div>
                  </Col>
                  <Col md={4}>

                  </Col>
                  {this.state.resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK && <Col md={4} >
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className={this.state.stafftypeerr?"DateTextErr":"DateText"}>{strings.stafftype}</span>
                  </Col>}
                  {this.state.resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK && <Col md={8}>
                    <div>
                      <Select size="large" className='bookingDatebox' disabled={!this.props.isEdit} value={this.state.staffType} placeholder={strings.pleasefill+' '+strings.stafftype} onChange={e => this.setState({ staffType: e })}>
                        {strings.getLanguage()==='en' ? this.state.systemData.staff_type_en.split(',').map((s,i)=><Option value={i+1}>{s}</Option>) :
                        this.state.systemData.staff_type_zh.split(',').map((s,i)=><Option value={i+1}>{s}</Option>)}
                      </Select>
                    </div>
                  </Col>}
                </Row>
                
                <Row xs={24} style={{ marginTop: 20, }} >                  
                  <Col md={4} >
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className="DateText">{strings.division}</span>
                  </Col>
                  <Col md={8}>
                    <div>
                      <Select size="large" className='bookingDatebox' disabled={!this.props.isEdit} placeholder={strings.pleasefill+' '+strings.division} value={this.state.division} onChange={e => this.setState({ division: e })}>
                        {strings.divisions.map((d) => <Option key={d} value={d}>{d}</Option>)}
                      </Select>
                    </div>
                  </Col>
                  <Col md={4} style={{ marginTop: 10 }}>
                    <span style={{ marginLeft: 12 }} className="DateText">{strings.remark}</span>
                  </Col>
                  <Col md={8}>
                    <div>
                      <Input size="large" className='bookingDatebox' disabled={!this.props.isEdit} value={this.state.remark} placeholder={strings.pleasefill+' '+strings.remark} onChange={e => this.setState({ remark: e.target.value })} />
                    </div>
                  </Col>
                </Row>
                <Row xs={24} style={{ marginTop: 20, }} >
                  <Col md={4}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className={this.state.cp1err ? "DateTextErr" : "DateText"}>{strings.contactperson1}</span>
                  </Col>
                  <Col md={8}>
                    <div>
                      <Select size="large" disabled={!this.props.isEdit} style={{ width: 80, marginRight:5 }} className="bookingselect" value={this.state.salutation1} onChange={e => this.setState({ salutation1: e })} >
                          <Option value="MR">{strings.MR}</Option>
                          <Option value="MS">{strings.MS}</Option>
                          <Option value="MISS">{strings.MISS}</Option>
                      </Select>
                      <Input disabled={!this.props.isEdit} size="large" className='bookingDatebox' value={this.state.contactPerson1} placeholder={strings.pleasefill+' '+strings.contactperson1} onChange={e => this.setState({ contactPerson1: e.target.value })} />
                    </div>
                  </Col>
                  <Col md={4}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className={this.state.cph1err ? "DateTextErr" : "DateText"}>{strings.contactphone1}</span>
                  </Col>
                  <Col md={8}>
                    <div>
                      <Input disabled={!this.props.isEdit} size="large" className='bookingDatebox' value={this.state.contactPhone1} placeholder={strings.pleasefill+' '+strings.contactphone1} onChange={e => this.setState({ contactPhone1: e.target.value })} />
                    </div>
                  </Col>
                </Row>

                <Row xs={24} style={{ marginTop: 20, }} >
                  <Col md={4} style={{ marginTop: 10 }}>
                    <span style={{ marginLeft: 12 }} className="DateText">{strings.contactperson2}</span>
                  </Col>
                  <Col md={8}>
                    <div>
                      <Select size="large" disabled={!this.props.isEdit} style={{ width: 80, marginRight:5 }} className="bookingselect" value={this.state.salutation2} onChange={e => this.setState({ salutation2: e })} >
                          <Option value="MR">{strings.MR}</Option>
                          <Option value="MS">{strings.MS}</Option>
                          <Option value="MISS">{strings.MISS}</Option>
                      </Select>
                      <Input disabled={!this.props.isEdit} size="large" className='bookingDatebox' value={this.state.contactPerson2} placeholder={strings.pleasefill+' '+strings.contactperson2} onChange={e => this.setState({ contactPerson2: e.target.value })} />
                    </div>
                  </Col>
                  <Col md={4} style={{ marginTop: 10 }}>
                    <span style={{ marginLeft: 12 }} className="DateText">{strings.contactphone2}</span>
                  </Col>
                  <Col md={8}>
                    <div>
                      <Input disabled={!this.props.isEdit} size="large" className='bookingDatebox' value={this.state.contactPhone2} placeholder={strings.pleasefill+' '+strings.contactphone2} onChange={e => this.setState({ contactPhone2: e.target.value })} />
                    </div>
                  </Col>
                </Row>
                <Row xs={24} style={{ marginTop: 20, }} >
                  <Col md={4}>
                    <span className="DateText" style={{ marginLeft: 12 }}>{strings.isreminder}</span>
                  </Col>
                  <Col md={1}>
                    <div>
                    <Checkbox disabled={!this.props.isEdit} onChange={(e) => this.onIsReminderChange(e)} style={{ marginLeft: 5 }} checked={this.state.isReminder}>{''}</Checkbox>
                    </div>
                  </Col>
                  <Col md={14}>
                    <span className="LongText2" style={{marginLeft:0}}>{strings.carparkremindermsg}</span>
                  </Col>
                </Row>

                {/* {this.state.isMechanized==='Y' && <div style={{ marginTop: 20}}>
                  <Checkbox onChange={(e)=>this.setState({isAgree:e.target.checked})} checked={this.state.isAgree} style={{fontSize:14,color:this.state.agreeerr?'#ff0000':'#405069'}}>{strings.termsandcondition} {' '}<a href='/srs_asset/disclaimer.pdf' target="_blank" style={{color:this.state.agreeerr?'#ff0000':'#405069',textDecoration:'underline'}}>{strings.termsandconditionlink}</a>{' '}{strings.termsandcondition1}</Checkbox>
                </div>} */}

                <div style={{ marginTop: 20, marginBottom: 30 }}>
                  <span>
                    <Button hidden={!this.props.isEdit} type="primary" size='large' style={{ marginRight: '8%' }} loading={this.state.loading} onClick={this.modifyBooking}>
                      {strings.edit}
                    </Button>
                    <Button size='large' style={{ marginRight: 20 }} onClick={this.props.backbtn}>
                      {strings.back}
                    </Button>
                    <Button hidden={!this.props.isEdit} size='large' style={{ marginRight: 20 }} onClick={this.clearScreen}>
                      {strings.clear}
                    </Button>
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Modal
          title={strings.alert}
          visible={this.state.showalertmodel}
          onCancel={() => this.setState({ showalertmodel: false })}
          footer={[
            <Button key="submit" className='subscribe1' type="danger" onClick={() => { this.setState({ showalertmodel: false }); if (this.state.triggerBack) this.props.backbtn(); }}>
              {strings.ok}
            </Button>,
          ]}
        >
          <span className="TitleOfTheBox">{strings.errormessage}</span><br />
          <span className="TitleOfTheBox1">{this.state.showalertmodel ? this.findErrorMessage(this.state.alertmessage) : ''}</span>
        </Modal>
      </div>
    );

  }
}

export default EditCarpark;